import * as array from 'src/lib/array';
import * as linkAPI from 'src/lib/linkAPI';
import * as models from 'src/models';
import * as types from './types';
import * as constants from './constants';

export { types, constants };

export const fromUnknown = (data: unknown): types.Institution => {
  return types.Institution.check(data);
};

export const fromLinkDTO = (data: linkAPI.dtos.institution.types.Institution): types.Institution => {
  return {
    entityKey: data.entity_key,
    institutionType: data.institution_type,
    displayName: data.display_name,
    displayNameReading: data.display_name_reading,
    status: data.status,
    statusReason: data.status_reason,
    certificateRequired: data.certificate_required,
    loginUrl: data.login_url,
    guidanceUrl: data.guidance_url,
    billingGroup: data.billing_group
  };
};

export const toVisible = (data: types.Institution): types.Institution | null =>
  toStatus(data) !== 'hidden' &&
  !(
    (/^au_/.test(data.entityKey) && !(/^au_wallet$/.test(data.entityKey) || /^au_credit_card$/.test(data.entityKey))) ||
    /fauxbank/.test(data.entityKey) ||
    /muntesting/.test(data.entityKey)
  )
    ? data
    : null;

export const toSupported = (data: types.Institution): types.Institution | null =>
  ['active', 'maintenance'].includes(toStatus(data)) ? data : null;

export const toStatus = (data: types.Institution): models.institutionStatus.types.InstitutionStatus =>
  array.matchLeft(
    (first) => first,
    'inactive',
    array.compact([
      data.status === 'active' ? ('active' as const) : null,
      data.status === 'inactive' &&
      (data.statusReason === 'legacy' || data.statusReason === 'test' || data.statusReason === 'unavailable')
        ? ('hidden' as const)
        : null,
      data.status === 'inactive' && data.statusReason === 'maintenance' ? ('maintenance' as const) : null,
      data.status === 'inactive' && data.statusReason === 'wont_support' ? ('wontSupport' as const) : null,
      data.status === 'inactive' && data.statusReason === 'unsupported' ? ('unsupported' as const) : null
    ])
  );

export const toTags = (data: types.Institution): models.institutionTag.types.InstitutionTag[] =>
  array.compact([
    'unit' as const,
    data.institutionType === 'bank' ? ('bankPersonal' as const) : null,
    data.institutionType === 'corporate' ? ('bankCorporate' as const) : null,
    data.institutionType === 'credit_card' ? ('creditCard' as const) : null,
    data.institutionType === 'point' ? ('pointCard' as const) : null,
    data.institutionType === 'stock' ? ('stock' as const) : null,
    data.institutionType === 'stored_value' ? ('storedValue' as const) : null,
    toStatus(data) === 'active' ? ('active' as const) : null,
    toStatus(data) === 'maintenance' ? ('maintenance' as const) : null,
    toStatus(data) === 'unsupported' ? ('willSupport' as const) : null,
    toStatus(data) === 'wontSupport' ? ('wontSupport' as const) : null
  ]);

export const toLoginTypes = (data: types.Institution): models.loginType.types.LoginType[] =>
  data.certificateRequired === null
    ? []
    : models.institutionLoginCertificateType.toLoginTypes(data.certificateRequired);

export const toLoginCertificateType = (
  data: types.Institution
): models.loginCertificateType.types.LoginCertificateType =>
  data.certificateRequired === null
    ? 'none'
    : models.institutionLoginCertificateType.toLoginCertificateType(data.certificateRequired);

export const orderByTypeAndName = (x: types.Institution, y: types.Institution): number => {
  return (
    x.institutionType.localeCompare(y.institutionType) ||
    (x.displayNameReading || '').localeCompare(y.displayNameReading || '')
  );
};
