import * as runtypes from 'runtypes';
import * as models from 'src/models';

export const State = models.visibleInstitutionRecord.types.VisibleInstitutionRecord;

export type State = runtypes.Static<typeof State>;

export const SetAction = runtypes.Record({
  type: runtypes.Literal('institutionRecord/set'),
  payload: State
});

export type SetAction = runtypes.Static<typeof SetAction>;

export const UpdateAction = runtypes.Record({
  type: runtypes.Literal('institutionRecord/update'),
  payload: State
});

export type UpdateAction = runtypes.Static<typeof UpdateAction>;

export const Action = runtypes.Union(SetAction, UpdateAction);

export type Action = runtypes.Static<typeof Action>;
