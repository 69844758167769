import * as runtypes from 'runtypes';
import * as institutionRecord from '../institutionRecord';

export const State = runtypes.Record({
  institutionRecord: institutionRecord.types.State
});

export type State = runtypes.Static<typeof State>;

export const Action = runtypes.Union(institutionRecord.types.Action);

export type Action = runtypes.Static<typeof Action>;

export type Config = {};
