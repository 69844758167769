import * as types from './types';

export { types };

export const toStatusCode = types.AppError.match(
  (http) => http.status,
  () => 500,
  () => 400,
  () => 500
);
