import * as runtypes from 'runtypes';
import * as institutionType from 'src/models/institutionType';
import * as institutionStatus from 'src/models/institutionStatus';

export const InstitutionFilterV2 = runtypes.Record({
  text: runtypes.String,
  type: runtypes.Union(runtypes.Literal('unit'), institutionType.types.InstitutionType),
  status: runtypes.Union(runtypes.Literal('unit'), institutionStatus.types.InstitutionStatus)
});

export type InstitutionFilterV2 = runtypes.Static<typeof InstitutionFilterV2>;
