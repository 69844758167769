import React, { useMemo } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import { cache } from '@emotion/css';
import { Global, CacheProvider } from '@emotion/react';
import { ClientConfigProvider } from 'src/contexts/ClientConfig';
import { LoggerProvider } from 'src/contexts/Logger';
import { ThemeProvider } from 'src/contexts/Theme';
import { MtLinksProvider } from 'src/contexts/MtLinks';
import { WindowProvider } from 'src/contexts/Window';
import * as client from 'src/configs/client';
import * as clientEnv from 'src/configs/clientEnv';
import * as mtLinks from 'src/configs/mtLinks';
import * as theme from 'src/styles/theme';
import * as store from 'src/store';
import * as styles from 'src/styles';
import Analytics from 'src/components/Analytics';
import SEO from 'src/components/SEO';

const App = ({ Component, pageProps }: AppProps) => {
  const clientConfig = useMemo(() => client.fromClientEnv(clientEnv.fromEnv()), []);
  return (
    <>
      <Head>
        <link href="/images/favicons/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
        <link href="/images/favicons/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/images/favicons/favicon-96x96.png" rel="icon" sizes="96x96" type="image/png" />
        <link href="/images/favicons/favicon.ico" rel="shortcut icon" />
      </Head>
      <WindowProvider value={typeof window === 'undefined' ? null : window}>
        <CacheProvider value={cache}>
          <Global styles={styles.settings.global(theme.main)} />
          <ClientConfigProvider value={clientConfig}>
            <LoggerProvider value={clientConfig.logger}>
              <Analytics />
              <SEO />
              <MtLinksProvider value={mtLinks.constants.production}>
                <Provider
                  store={useMemo(
                    () => store.create(store.state.reducers.initialState)(clientConfig.store),
                    [clientConfig.store]
                  )}
                >
                  <ThemeProvider value={theme.main}>
                    <Component {...pageProps} />
                  </ThemeProvider>
                </Provider>
              </MtLinksProvider>
            </LoggerProvider>
          </ClientConfigProvider>
        </CacheProvider>
      </WindowProvider>
    </>
  );
};

export default appWithTranslation(App);
