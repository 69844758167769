import React from 'react';
import useClientConfig from 'src/hooks/useClientConfig';
import usePageview from 'src/hooks/usePageview';
import Gtag from 'src/components/Gtag';

const Analytics: React.FC = () => {
  usePageview();
  const clientConfig = useClientConfig();
  return <>{clientConfig.gtag && <Gtag config={clientConfig.gtag} />}</>;
};

export default Analytics;
