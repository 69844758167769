import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import useClientConfig from 'src/hooks/useClientConfig';
import useWindow from 'src/hooks/useWindow';
import * as gtag from 'src/lib/gtag';

const usePageview = () => {
  const window = useWindow();
  const router = useRouter();
  const clientConfig = useClientConfig();

  const dispatch = useCallback(
    (path: string) => {
      if (clientConfig.gtag && window?.gtag) {
        gtag.pageview(path)(clientConfig.gtag)(window.gtag.bind(window));
      }
    },
    [clientConfig.gtag, window]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', dispatch);
    return () => router.events.off('routeChangeComplete', dispatch);
  }, [router.events]);
};

export default usePageview;
