import * as runtypes from 'runtypes';

export const InstitutionTag = runtypes.Union(
  runtypes.Literal('unit'),
  runtypes.Literal('bankPersonal'),
  runtypes.Literal('bankCorporate'),
  runtypes.Literal('creditCard'),
  runtypes.Literal('pointCard'),
  runtypes.Literal('stock'),
  runtypes.Literal('storedValue'),
  runtypes.Literal('active'),
  runtypes.Literal('maintenance'),
  runtypes.Literal('willSupport'),
  runtypes.Literal('wontSupport')
);

export type InstitutionTag = runtypes.Static<typeof InstitutionTag>;
