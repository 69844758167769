import RequestError from '../request';

type Parameters = {
  request: Request;
};

class NetworkError extends RequestError {
  constructor({ request }: Parameters) {
    super({ request, message: 'Request failed with a network error' });
    this.name = 'NetworkError';

    // necessary workaround for https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

export default NetworkError;
