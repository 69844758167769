import { Reducer } from '@reduxjs/toolkit';
import * as types from '../types';

export const initialState: types.State = {};

export const root: Reducer<types.State> = (state = initialState, action) => {
  return types.Action.guard(action)
    ? types.Action.match(
        (action) => action.payload,
        (action) => ({ ...state, ...action.payload })
      )(action)
    : state;
};
