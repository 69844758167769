import * as models from 'src/models';
import * as runtypes from 'runtypes';
import * as featureDecision from 'src/models/featureDecision';

export const Config = runtypes.Record({
  ENVIRONMENT: models.environment.types.Environment,
  FEATURE_DECISION_LOGGER: featureDecision.types.FeatureDecision,
  FEATURE_DECISION_STORE_DEV_TOOLS: featureDecision.types.FeatureDecision,
  FEATURE_DECISION_INSTITUTION_FILTER_V2: featureDecision.types.FeatureDecision,
  GOOGLE_ANALYTICS_ID: runtypes.String.Or(runtypes.Undefined),
  GOOGLE_SITE_VERIFICATION_ID: runtypes.String.Or(runtypes.Undefined)
});

export type Config = runtypes.Static<typeof Config>;
