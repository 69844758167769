import React from 'react';
import Script from 'next/script';
import * as gtag from 'src/lib/gtag';

export type Props = {
  config: gtag.types.Config;
};

const Gtag: React.FC<Props> = ({ config }) => {
  return (
    <>
      <Script defer src={`https://www.googletagmanager.com/gtag/js?id=${config.id}`} strategy="afterInteractive" />
      <Script
        id="gtag" /* https://github.com/vercel/next.js/issues/9070 */
        defer
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${config.id}');
            `
        }}
        strategy="afterInteractive"
      />
    </>
  );
};

export default Gtag;
