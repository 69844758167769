import { combineReducers } from 'redux';
import * as institutionRecord from '../institutionRecord';
import * as types from '../types';

export const initialState: types.State = {
  institutionRecord: institutionRecord.reducers.initialState
};

export const root = combineReducers<types.State>({
  institutionRecord: institutionRecord.reducers.root
});
