import { constant } from 'src/lib/function';
import * as types from './types';

export { types };

export const unit: types.Log<unknown> = constant(null);

export const concat = <A>(mx: types.Log<A>, my: types.Log<A>): types.Log<A> => {
  return (data) => constant(my(data))(mx(data));
};

export const sequence = <A>(mxs: ReadonlyArray<types.Log<A>>): types.Log<A> => {
  return mxs.reduce(concat, unit);
};

export const contramap = <A, B>(fn: (x: B) => A, mx: types.Log<A>): types.Log<B> => {
  return (data) => mx(fn(data));
};
