import * as models from 'src/models';

export const toLocalizedString =
  (locale: models.locale.types.Locale) =>
  (model: Date): string => {
    return models.locale.types.Locale.match(
      () => `${model.getUTCFullYear()}年${model.getUTCMonth() + 1}月${model.getUTCDate()}日`,
      () => `${model.toLocaleString('en-GB', { month: 'long' })} ${model.getUTCDate()}, ${model.getUTCFullYear()}`
    )(locale);
  };
