import * as types from '../types';

export const production: types.Config = {
  ja: {
    corporate: 'https://getmoneytree.com/jp/home',
    privacy: 'https://assets.moneytree.jp/legal/jp/tos-and-pp-ja-nf.html#privacy',
    termsOfService: 'https://assets.moneytree.jp/legal/jp/tos-and-pp-ja-nf.html#terms',
    faq: 'https://help.getmoneytree.com/ja',
    faqCreditCard: 'https://help.getmoneytree.com/ja/articles/407716-affiliated-credit-cards'
  },
  en: {
    corporate: 'https://getmoneytree.com/jp/home',
    privacy: 'https://assets.moneytree.jp/legal/jp/tos-and-pp-ja-nf.html#privacy',
    termsOfService: 'https://assets.moneytree.jp/legal/jp/tos-and-pp-ja-nf.html#terms',
    faq: 'https://help.getmoneytree.com/en',
    faqCreditCard: 'https://help.getmoneytree.com/en/articles/407716-affiliated-credit-cards'
  }
};
