import type { InstitutionFilterV2 } from '../types';

export const empty: InstitutionFilterV2 = {
  text: '',
  type: 'unit',
  status: 'unit',
};

export const types: InstitutionFilterV2['type'][] = [
  'unit',
  'bank',
  'corporate',
  'credit_card',
  'point',
  'stock',
  'stored_value'
];

export const statuses: InstitutionFilterV2['status'][] = [
  'unit',
  'active',
  'unsupported',
  'maintenance',
  'wontSupport'
];
