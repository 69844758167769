import * as runtypes from 'runtypes';
import * as http from 'src/lib/http';

export const AppError = runtypes.Union(
  runtypes.InstanceOf(http.errors.HttpError),
  runtypes.InstanceOf(http.errors.NetworkError),
  runtypes.InstanceOf(http.errors.RequestError),
  runtypes.InstanceOf(Error)
);

export type AppError = runtypes.Static<typeof AppError>;
