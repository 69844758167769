import React from 'react';
import useClientConfig from 'src/hooks/useClientConfig';
import GoogleSearchConsole from 'src/components/GoogleSearchConsole';

const SEO: React.FC = () => {
  const clientConfig = useClientConfig();
  return <>{clientConfig.googleSearchConsole && <GoogleSearchConsole config={clientConfig.googleSearchConsole} />}</>;
};

export default SEO;
