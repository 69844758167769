import { configureStore } from '@reduxjs/toolkit';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import * as state from './state';
import * as types from './types';

export { state, types };

export const create =
  (preloadedState: Partial<types.State> = {}) =>
  (config: types.Config): types.Store =>
    configureStore<types.State, types.Action, [ThunkMiddleware<types.State, types.Action, state.types.Config>]>({
      preloadedState: { ...state.reducers.initialState, ...preloadedState },
      devTools: config.features.devTools === 'enabled',
      reducer: state.reducers.root,
      middleware: [thunk.withExtraArgument<state.types.Config>(config.state)]
    });
