import * as types from './types';

export { types };

export const fromString = (data: string): types.Locale => {
  return fromUnknown(data.toLowerCase());
};

export const fromUnknown = (data: unknown): types.Locale => {
  return types.Locale.check(data);
};

export const toggle = (data: types.Locale): types.Locale => (data === 'en' ? 'ja' : 'en');
