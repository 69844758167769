import * as result from 'src/lib/result';
import * as googleSearchConsole from 'src/lib/googleSearchConsole';
import * as gtag from 'src/lib/gtag';
import * as logger from 'src/configs/logger';
import * as clientEnv from 'src/configs/clientEnv';
import * as constants from './constants';
import * as types from './types';

export { constants, types };

export const fromClientEnv = (input: clientEnv.types.Config): types.Config => ({
  environment: input.ENVIRONMENT,
  logger: logger.fromClientEnv(input),
  store: {
    state: {},
    features: {
      devTools: input.FEATURE_DECISION_STORE_DEV_TOOLS
    }
  },
  features: {
    institutionFilterV2: input.FEATURE_DECISION_INSTITUTION_FILTER_V2
  },
  gtag: result.toNullable(
    gtag.types.Config.validate({
      id: input.GOOGLE_ANALYTICS_ID
    })
  ),
  googleSearchConsole: result.toNullable(
    googleSearchConsole.types.Config.validate({
      verificationID: input.GOOGLE_SITE_VERIFICATION_ID
    })
  )
});
