export const append = <A>(x: A, xs: A[]): A[] => {
  return [...xs, x];
};

export const intersperse = <A>(x: A, xs: A[]) => {
  return prependAll(x, xs).slice(1);
};

export const prependAll = <A>(x: A, xs: A[]) => {
  return bind((_x) => [x, _x], xs);
};

export const concat = <A>(xss: A[][]): A[] => {
  const out: A[] = [];
  xss.forEach((xs) => {
    xs.forEach((x) => {
      out.push(x);
    });
  });
  return out;
};

export const bind = <A, B>(fn: (x: A) => B[], xs: A[]): B[] => {
  const out: B[] = [];
  xs.forEach((x) => {
    fn(x).forEach((y) => {
      out.push(y);
    });
  });
  return out;
};

export const nub = <A extends string | number>(xs: A[]): A[] => {
  return nubBy((i) => i, xs);
};

export const nubBy = <A, B extends string | number>(fn: (x: A) => B, xs: A[]): A[] => {
  return xs.reduce(
    (acc, x) => {
      const key = fn(x);
      if (!acc[1][key]) {
        acc[1][key] = true;
        acc[0].push(x);
      }
      return acc;
    },
    [[], {} as Record<B, boolean>] as [A[], Record<B, boolean>]
  )[0];
};

export const head = <A>(xs: A[]): A | null => {
  return xs.length > 0 ? xs[0] : null;
};

export const init = <A>(xs: A[]): [A | null, A[]] => {
  return [head(xs), xs.slice(1)];
};

export const groupBy = <A, K extends string | number>(fn: (x: A) => K, xs: A[]): Record<K, A[]> => {
  return xs.reduce((acc, x) => {
    const key = fn(x);
    if (key in acc) {
      acc[key].push(x);
    } else {
      acc[key] = [x];
    }
    return acc;
  }, {} as Record<K, A[]>);
};

export const replicate = <A>(n: number, x: A): A[] => {
  return new Array(n).fill(x);
};

export const zipWith = <A, B, C>(fn: (x: A, y: B, i: number) => C, xs: A[], ys: B[]): C[] => {
  const out: C[] = [];
  for (let i = 0; i < Math.min(xs.length, ys.length); i += 1) {
    out.push(fn(xs[i], ys[i], i));
  }
  return out;
};

export const zip = <A, B>(xs: A[], ys: B[]): [A, B][] => {
  return zipWith((x, y) => [x, y] as [A, B], xs, ys);
};

export const filterMap = <A, B>(fn: (x: A) => B | null, xs: A[]): B[] => {
  return xs.reduce((acc, x) => {
    const mx = fn(x);
    if (mx !== null) {
      acc.push(mx);
    }
    return acc;
  }, [] as B[]);
};

export const matchLeft = <A, B>(fn: (x: A, xs: A[]) => B, fb: B, xs: A[]): B => {
  const [x, rest] = init(xs);
  return x === null ? fb : fn(x, rest);
};

export const lookup = <A>(index: number, xs: A[]): A | null => {
  return xs[index];
};

export const compact = <A>(xs: Array<A | null>): Array<A> => {
  return filterMap((i) => i, xs);
};
