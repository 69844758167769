import { css } from '@emotion/css';
import * as types from 'src/lib/theme/types';

export const main: types.Theme = {
  typography: {
    body: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
    `,
    caption: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-size: 12px;
      line-height: 20px;
    `,
    subtitle: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-size: 17px;
      line-height: 20px;
    `,
    subhead: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    `,
    h1: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 34px;
      line-height: 37.4px;
    `,
    h2: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 28px;
    `,
    h3: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 24px;
      line-height: 37.4px;
    `,
    h4: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 18px;
    `,
    input: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-size: 16px;
    `,
    strong: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.42857143;
    `,
    button: css`
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.42857143;
    `
  },
  weight: {
    strong: css`
      font-style: normal;
      font-weight: 700;
    `,
    normal: css`
      font-style: normal;
    `
  },
  layer: {
    bottom: css`
      z-index: -1;
    `,
    header: css`
      z-index: 1;
    `
  },
  color: {
    white: '#fff',
    black: '#333',
    glow: '#e5ffea',
    grey20: '#f1f3f5',
    grey30: '#e9ecef',
    grey60: '#adb5bd',
    grey80: '#777',
    grey90: '#3a4147',
    green10: '#e5ffea',
    green40: '#8aeb98',
    green60: '#11A629',
    green80: '#34b44b',
    greenA50: '#16d936',
    blue10: '#e5fffe',
    blue40: '#63eae9',
    yellow10: '#fffae0',
    yellow40: '#ffdf66',
    red10: '#fff4f2',
    red40: '#ffb2a8',
    red80: '#c00'
  },
  breakpoint: {
    xs: { to: '599px' },
    sm: { from: '600px', to: '767px' },
    md: { from: '768px', to: '991px' },
    lg: { from: '992px', to: '1199px' },
    xl: { from: '1200px' }
  }
};
