import * as runtypes from 'runtypes';
import { identity } from 'src/lib/function';
import * as logger from 'src/lib/logger';
import * as clientEnv from 'src/configs/clientEnv';
import * as serverEnv from 'src/configs/serverEnv';
import * as types from './types';

export { types };

export const fromClientEnv = (input: clientEnv.types.Config): types.Config => {
  return runtypes.match(
    runtypes.when(runtypes.Literal('enabled'), () => logger.contramap<string, string>(identity, logger.global)),
    runtypes.when(runtypes.Literal('disabled'), () => logger.unit)
  )(input.FEATURE_DECISION_LOGGER);
};

export const fromServerEnv = (input: serverEnv.types.Config): types.Config => {
  return runtypes.match(
    runtypes.when(runtypes.Literal('enabled'), () => logger.contramap<string, string>(identity, logger.global)),
    runtypes.when(runtypes.Literal('disabled'), () => logger.unit)
  )(input.FEATURE_DECISION_LOGGER);
};
