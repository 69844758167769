import * as cache from 'src/lib/cache';
import * as linkAPI from 'src/lib/linkAPI';
import * as types from './types';

export { types };

export const fromCache = (data: cache.types.Cache<string>): types.Oauth =>
  fromUnknown({
    accessToken: data.value,
    createdAt: data.createdAt,
    expiresIn: data.expiresIn
  });

export const fromLinkDTO = (data: linkAPI.dtos.oauth.types.Oauth): types.Oauth =>
  fromUnknown({
    accessToken: data.access_token,
    createdAt: data.created_at,
    expiresIn: data.expires_in
  });

export const fromUnknown = (data: unknown): types.Oauth => types.Oauth.check(data);

export const toCache = (data: types.Oauth): cache.types.Cache<types.Oauth> => {
  return {
    value: data,
    createdAt: data.createdAt,
    expiresIn: data.expiresIn
  };
};

export const expired = (data: types.Oauth): boolean => {
  return new Date().getTime() >= data.createdAt + data.expiresIn * 1000;
};

export const setLinkAPIAccessToken = (data: types.Oauth) => (env: linkAPI.types.Env) => {
  return {
    ...env,
    config: {
      ...env.config,
      token: data.accessToken
    }
  };
};
