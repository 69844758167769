import { Result } from 'runtypes';
import { constant, identity } from 'src/lib/function';
import { Serialize } from 'src/lib/serialize/types';
import * as result from 'src/lib/result';
import * as parser from 'src/lib/parser';
import * as models from 'src/models';
import * as types from './types';
import * as constants from './constants';

export { types, constants };

export const fromString = (x: string): Result<types.InstitutionFilterV2> => {
  return types.InstitutionFilterV2.validate(parser.json.parse(x));
};

export const toString = (x: types.InstitutionFilterV2): string => {
  return JSON.stringify(x);
};

export const equal = (x: types.InstitutionFilterV2, y: types.InstitutionFilterV2) => {
  return (
    x.text.toLowerCase() === y.text.toLowerCase() &&
    x.type === y.type &&
    x.status === y.status
  );
};

export const match = (data: models.institution.types.Institution) => (model: types.InstitutionFilterV2) =>
  equal(model, constants.empty) ||
  ([data.displayName || '', data.displayNameReading || ''].some((x) =>
    x.toLowerCase().includes(model.text.toLowerCase())
  ) &&
    (model.type === 'unit' || data.institutionType === model.type) &&
    (model.status === 'unit' || models.institution.toStatus(data) === model.status));

export const serialize: Serialize<types.InstitutionFilterV2> = {
  toString,
  fromString: (x) => result.fold(constant(constants.empty), identity, fromString(x))
};
