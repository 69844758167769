import * as types from '../types';

export const tags: types.InstitutionTag[] = [
  'unit',
  'bankPersonal',
  'bankCorporate',
  'creditCard',
  'pointCard',
  'stock',
  'storedValue',
  'active',
  'maintenance',
  'willSupport',
  'wontSupport'
];
