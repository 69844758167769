import * as types from '../types';

export const set = (payload: types.SetAction['payload']): types.SetAction => ({
  type: 'institutionRecord/set',
  payload
});

export const update = (payload: types.UpdateAction['payload']): types.UpdateAction => ({
  type: 'institutionRecord/update',
  payload
});
