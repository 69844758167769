import * as runtypes from 'runtypes';

export const System = runtypes.Union(
  runtypes.Literal('hitachi'),
  runtypes.Literal('ibm'),
  runtypes.Literal('nri'),
  runtypes.Literal('ntt')
);

export type System = runtypes.Static<typeof System>;
