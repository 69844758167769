import RequestError from '../request';

type Parameters = {
  request: Request;
  status: number;
};

class HttpError extends RequestError {
  readonly status: number;

  constructor({ request, status }: Parameters) {
    super({ request, message: `Request failed with ${status} error` });
    this.name = 'HttpError';
    this.status = status;

    // necessary workaround for https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

export default HttpError;
