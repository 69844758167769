import { createSelector } from '@reduxjs/toolkit';
import * as types from '../types';

export const makeSelectState = () =>
  createSelector(
    (state: types.State) => state,
    (i) => i
  );

export const makeSelectInstitutionRecord = () =>
  createSelector([makeSelectState()], (state) => state.institutionRecord);
