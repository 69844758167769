import * as runtypes from 'runtypes';

export const InstitutionType = runtypes.Union(
  runtypes.Literal('bank'),
  runtypes.Literal('credit_card'),
  runtypes.Literal('stored_value'),
  runtypes.Literal('point'),
  runtypes.Literal('corporate'),
  runtypes.Literal('stock')
);

export type InstitutionType = runtypes.Static<typeof InstitutionType>;
