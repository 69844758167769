type Parameters = {
  message: string;
  request: Request;
};

class RequestError extends Error {
  constructor({ message, request }: Parameters) {
    super(`Failed calling ${request.url}: ${message}`);
    this.name = 'RequestError';

    // necessary workaround for https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export default RequestError;
