import { Result } from 'runtypes';
import { Serialize } from 'src/lib/serialize/types';
import { constant, identity } from 'src/lib/function';
import * as parser from 'src/lib/parser';
import * as result from 'src/lib/result';
import * as models from 'src/models';
import * as types from './types';
import * as constants from './constants';

export { types, constants };

export const fromString = (x: string): Result<types.InstitutionFilter> => {
  return types.InstitutionFilter.validate(parser.json.parse(x));
};

export const toString = (x: types.InstitutionFilter): string => {
  return JSON.stringify(x);
};

export const equal = (x: types.InstitutionFilter, y: types.InstitutionFilter) => {
  return x.text.toLowerCase() === y.text.toLowerCase() && x.tag === y.tag;
};

export const match = (data: models.institution.types.Institution) => (model: types.InstitutionFilter) =>
  equal(model, constants.empty) ||
  ([data.displayName || '', data.displayNameReading || ''].some((x) =>
    x.toLowerCase().includes(model.text.toLowerCase())
  ) &&
    models.institution.toTags(data).includes(model.tag));

export const serialize: Serialize<types.InstitutionFilter> = {
  toString,
  fromString: (x) => result.fold(constant(constants.empty), identity, fromString(x))
};
