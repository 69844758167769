import * as types from '../types';

export const unit: types.Institution = {
  institutionType: 'bank',
  entityKey: '',
  displayName: null,
  displayNameReading: null,
  status: 'inactive',
  statusReason: null,
  certificateRequired: null,
  loginUrl: null,
  guidanceUrl: null,
  billingGroup: null
};
