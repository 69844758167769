import * as string from 'src/lib/string';

export const lookup = <A extends string, B>(k: A, rx: Record<A, B>): B | null => {
  return k in rx ? rx[k] : null;
};

export const collect = <A extends string, B, C>(
  compare: (x: A, y: A) => number,
  fn: (k: A, x: B) => C,
  rx: Record<A, B>
): C[] => {
  const out: C[] = [];
  for (const k of keys(compare, rx)) {
    out.push(fn(k, rx[k]));
  }
  return out;
};

export const toArray = <A extends string, B>(rx: Record<A, B>): [A, B][] => {
  return collect(string.compare, (k, v) => [k, v], rx);
};

export const fromArray = <A extends string, B>(xs: [A, B][]): Record<A, B> => {
  return xs.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as Record<A, B>);
};

export const keys = <A extends string, B>(compare: (x: A, y: A) => number, rx: Record<A, B>): A[] => {
  return (Object.keys(rx) as A[]).sort(compare);
};

export const values = <A extends string, B>(compare: (x: A, y: A) => number, rx: Record<A, B>): B[] => {
  return collect(compare, (_, v) => v, rx);
};

export const map = <A extends string, B, C>(fn: (x: B) => C, rx: Record<A, B>): Record<A, C> => {
  const out = {} as Record<A, C>;
  for (const k in rx) {
    out[k] = fn(rx[k]);
  }
  return out;
};

export const filter = <A extends string, B>(fn: (x: B) => boolean, rx: Record<A, B>): Record<A, B> => {
  const out = {} as Record<A, B>;
  for (const k in rx) {
    if (fn(rx[k])) {
      out[k] = rx[k];
    }
  }
  return out;
};

export const filterMap = <A extends string, B, C>(fn: (x: B) => C | null, rx: Record<A, B>): Record<A, C> => {
  const out = {} as Record<A, C>;
  for (const k in rx) {
    const v = fn(rx[k]);
    if (v !== null) {
      out[k] = v;
    }
  }
  return out;
};
