import * as types from './types';

export { types };

export const fromEnv = (): types.Config => {
  return types.Config.check({
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
    FEATURE_DECISION_LOGGER: process.env.NEXT_PUBLIC_FEATURE_DECISION_LOGGER || 'disabled',
    FEATURE_DECISION_STORE_DEV_TOOLS: process.env.NEXT_PUBLIC_FEATURE_DECISION_STORE_DEV_TOOLS || 'disabled',
    FEATURE_DECISION_INSTITUTION_FILTER_V2:
      process.env.NEXT_PUBLIC_FEATURE_DECISION_INSTITUTION_FILTER_V2 || 'disabled',
    GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
    GOOGLE_SITE_VERIFICATION_ID: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID
  });
};
