import * as runtypes from 'runtypes';

export enum InstitutionLoginCertificateTypeEnum {
  NONE,
  OPTIONAL,
  REQUIRED
}

export const InstitutionLoginCertificateType = runtypes.Union(
  runtypes.Literal(InstitutionLoginCertificateTypeEnum.NONE),
  runtypes.Literal(InstitutionLoginCertificateTypeEnum.OPTIONAL),
  runtypes.Literal(InstitutionLoginCertificateTypeEnum.REQUIRED)
);

export type InstitutionLoginCertificateType = runtypes.Static<typeof InstitutionLoginCertificateType>;
