import * as runtypes from 'runtypes';

export const Config = runtypes.Record({
  id: runtypes.String
});

export type Config = runtypes.Static<typeof Config>;

export const Event = runtypes.Record({
  action: runtypes.String,
  category: runtypes.String,
  label: runtypes.String,
  value: runtypes.String
});

export type Event = runtypes.Static<typeof Event>;
