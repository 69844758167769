import * as logger from 'src/lib/logger';
import * as types from '../types';

export const unit: types.Config = {
  logger: logger.unit,
  environment: 'development',
  store: {
    state: {},
    features: {
      devTools: 'disabled'
    }
  },
  features: {
    institutionFilterV2: 'disabled'
  },
  gtag: null,
  googleSearchConsole: null
};
