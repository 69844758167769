import * as array from 'src/lib/array';
import * as string from 'src/lib/string';
import * as record from 'src/lib/record';
import * as models from 'src/models';
import * as types from './types';

export { types };

export const fromInstitutions = (data: models.institution.types.Institution[]): types.InstitutionRecord => {
  return record.filterMap(
    array.head,
    array.groupBy((institution) => institution.entityKey, data)
  );
};

export const toEntries = (data: types.InstitutionRecord): models.institution.types.Institution[] => {
  return record.values(string.compare, data).sort(models.institution.orderByTypeAndName);
};

export const toEntriesByIDs =
  (ids: Array<keyof types.InstitutionRecord>) =>
  (data: types.InstitutionRecord): models.institution.types.Institution[] => {
    return array.filterMap((id) => record.lookup(id, data), ids);
  };

export const toIDs = (data: types.InstitutionRecord): (keyof types.InstitutionRecord)[] => {
  return toEntries(data).map((entry) => entry.entityKey);
};

export const filterByInstitutionFilter =
  (filter: models.institutionFilter.types.InstitutionFilter) => (data: types.InstitutionRecord) => {
    return record.filter((x) => models.institutionFilter.match(x)(filter), data);
  };

export const filterByInstitutionFilterV2 =
  (filter: models.institutionFilterV2.types.InstitutionFilterV2) => (data: types.InstitutionRecord) => {
    return record.filter((x) => models.institutionFilterV2.match(x)(filter), data);
  };

export const filterSupported = (data: types.InstitutionRecord) => {
  return record.filterMap(models.institution.toSupported, data);
};
