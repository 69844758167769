import * as log from 'src/lib/log';
import * as types from './types';

export { types };

export const unit: types.Logger<unknown> = {
  log: log.unit,
  info: log.unit,
  warn: log.unit,
  error: log.unit,
  debug: log.unit
};

export const global: types.Logger<string> = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
  debug: console.debug
};

export const contramap = <A, B>(fn: (x: B) => A, mx: types.Logger<A>): types.Logger<B> => {
  return {
    log: log.contramap(fn, mx.log),
    info: log.contramap(fn, mx.info),
    warn: log.contramap(fn, mx.warn),
    error: log.contramap(fn, mx.error),
    debug: log.contramap(fn, mx.debug)
  };
};
