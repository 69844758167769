import * as types from './types';

export { types };

export const pageview =
  (path: string) =>
  (config: types.Config) =>
  (gtag: Gtag.Gtag): void => {
    gtag('config', config.id, {
      page_path: path
    });
  };

export const event =
  (e: types.Event) =>
  (gtag: Gtag.Gtag): void => {
    gtag('event', e.action, {
      event_category: e.category,
      event_label: e.label,
      value: e.value
    });
  };
