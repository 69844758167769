import React from 'react';
import Head from 'next/head';
import * as googleSearchConsole from 'src/lib/googleSearchConsole';

export type Props = {
  config: googleSearchConsole.types.Config;
};

const GoogleSearchConsole: React.FC<Props> = (props) => {
  return (
    <>
      <Head>
        <meta name="google-site-verification" content={props.config.verificationID} />
      </Head>
    </>
  );
};

export default GoogleSearchConsole;
