import { Result } from 'runtypes';
import { constant, identity } from 'src/lib/function';

export const fmap = <A, B>(fn: (x: A) => B, mx: Result<A>): Result<B> => {
  return mx.success ? { ...mx, value: fn(mx.value) } : mx;
};

export const fold = <A, B>(onLeft: () => B, onRight: (x: A) => B, mx: Result<A>): B => {
  return mx.success ? onRight(mx.value) : onLeft();
};

export const toNullable = <A>(rx: Result<A>): A | null => {
  return fold<A, A | null>(constant(null), identity, rx);
};
