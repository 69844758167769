import * as runtypes from 'runtypes';
import * as institutionType from 'src/models/institutionType';
import * as institutionLoginCertificateType from 'src/models/institutionLoginCertificateType';

export const Institution = runtypes.Record({
  institutionType: institutionType.types.InstitutionType,
  entityKey: runtypes.String,
  displayName: runtypes.String.Or(runtypes.Null),
  displayNameReading: runtypes.String.Or(runtypes.Null),
  status: runtypes.Union(runtypes.Literal('active'), runtypes.Literal('inactive')).Or(runtypes.Null),
  statusReason: runtypes.String.Or(runtypes.Null),
  certificateRequired: institutionLoginCertificateType.types.InstitutionLoginCertificateType.Or(runtypes.Null),
  loginUrl: runtypes.String.Or(runtypes.Null),
  guidanceUrl: runtypes.String.Or(runtypes.Null),
  billingGroup: runtypes.String.Or(runtypes.Null)
});

export type Institution = runtypes.Static<typeof Institution>;
