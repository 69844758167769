import * as types from './types';

export { types };

export const just = <A>(x: A): types.Just<A> => ({
  tag: 'just',
  value: x
});

export const nothing: types.Nothing = {
  tag: 'nothing'
};

export const fmap = <A, B>(fn: (x: A) => B) => {
  return bind<A, B>((x) => just(fn(x)));
};

export const bind =
  <A, B>(fn: (x: A) => types.Maybe<B>) =>
  (mx: types.Maybe<A>): types.Maybe<B> => {
    return mx.tag === 'just' ? fn(mx.value) : mx;
  };

export const fold =
  <A, B>(fn: () => B, gn: (x: A) => B) =>
  (mx: types.Maybe<A>) => {
    return mx.tag === 'nothing' ? fn() : gn(mx.value);
  };

export const fromNullable = <A>(x: A | null): types.Maybe<A> => {
  return x === null ? nothing : just(x);
};

export const toNullable = <A>(mx: types.Maybe<A>): A | null => {
  return mx.tag === 'nothing' ? null : mx.value;
};
