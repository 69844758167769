import { createSelector } from 'reselect';
import * as record from 'src/lib/record';
import * as models from 'src/models';
import * as types from '../types';

export const makeSelectState = () =>
  createSelector(
    (state: types.State) => state,
    (i) => i
  );

export const makeSelectIDs = () => createSelector(makeSelectState(), models.institutionRecord.toIDs);

export const makeSelectSupportedIDs = () =>
  createSelector(makeSelectState(), (state) =>
    models.institutionRecord.toIDs(models.institutionRecord.filterSupported(state))
  );

export const makeSelectAll = () => createSelector(makeSelectState(), models.institutionRecord.toEntries);

export const makeSelectSupported = () =>
  createSelector(makeSelectState(), (state) =>
    models.institutionRecord.toEntries(models.institutionRecord.filterSupported(state))
  );

export const makeSelectByID = (id: keyof types.State) =>
  createSelector(makeSelectState(), (state) => record.lookup(id, state));

export const makeSelectByIDs = (ids: (keyof types.State)[]) =>
  createSelector(makeSelectState(), models.institutionRecord.toEntriesByIDs(ids));

export const makeSelectIDsByFilter = (filter: models.institutionFilter.types.InstitutionFilter) =>
  createSelector(makeSelectState(), (state) =>
    models.institutionRecord.toIDs(models.institutionRecord.filterByInstitutionFilter(filter)(state))
  );

export const makeSelectIDsByFilterV2 = (filter: models.institutionFilterV2.types.InstitutionFilterV2) =>
  createSelector(makeSelectState(), (state) =>
    models.institutionRecord.toIDs(models.institutionRecord.filterByInstitutionFilterV2(filter)(state))
  );
