import * as runtypes from 'runtypes';
import { flow, pipe } from 'src/lib/function';
import * as maybe from 'src/lib/maybe';
import * as parser from 'src/lib/parser';
import * as types from './types';

export { types };

export const fromWindow = (w: Window): types.Scroll => {
  return {
    x: w.scrollX,
    y: w.scrollY
  };
};

export const fromString = (data: string): runtypes.Result<types.Scroll> => {
  return types.Scroll.validate(
    pipe(
      maybe.fromNullable(parser.json.parse(data)),
      maybe.bind((json) =>
        pipe(
          maybe.fromNullable(parser.string.parse(json.x)),
          maybe.bind(flow(parser.number.parse, maybe.fromNullable)),
          maybe.bind((x) =>
            pipe(
              maybe.fromNullable(parser.string.parse(json.y)),
              maybe.bind(flow(parser.number.parse, maybe.fromNullable)),
              maybe.fmap((y) => ({ x, y }))
            )
          )
        )
      ),
      maybe.toNullable
    )
  );
};

export const toString = (data: types.Scroll): string => {
  return JSON.stringify(data);
};
