import * as array from 'src/lib/array';
import * as loginType from 'src/models/loginType';
import * as loginCertificateType from 'src/models/loginCertificateType';
import * as types from './types';

export { types };

export const fromLoginCertificateType =
  loginCertificateType.types.LoginCertificateType.match<types.InstitutionLoginCertificateType>(
    () => types.InstitutionLoginCertificateTypeEnum.NONE,
    () => types.InstitutionLoginCertificateTypeEnum.OPTIONAL,
    () => types.InstitutionLoginCertificateTypeEnum.REQUIRED
  );

export const toLoginCertificateType =
  types.InstitutionLoginCertificateType.match<loginCertificateType.types.LoginCertificateType>(
    () => 'none',
    () => 'optional',
    () => 'required'
  );

export const toLoginTypes = (data: types.InstitutionLoginCertificateType): loginType.types.LoginType[] => {
  return array.compact([
    data === types.InstitutionLoginCertificateTypeEnum.OPTIONAL ||
    data === types.InstitutionLoginCertificateTypeEnum.NONE
      ? ('password' as const)
      : null,
    data === types.InstitutionLoginCertificateTypeEnum.OPTIONAL ||
    data === types.InstitutionLoginCertificateTypeEnum.REQUIRED
      ? ('certificate' as const)
      : null
  ]);
};
