import * as runtypes from 'runtypes';

export const InstitutionStatus = runtypes.Union(
  runtypes.Literal('active'),
  runtypes.Literal('inactive'),
  runtypes.Literal('hidden'),
  runtypes.Literal('maintenance'),
  runtypes.Literal('wontSupport'),
  runtypes.Literal('unsupported')
);

export type InstitutionStatus = runtypes.Static<typeof InstitutionStatus>;
